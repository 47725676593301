export default {
	name: 'DecksAndSlides',
	created () {
		this.$nextTick(function () {

		})
	},
	mounted () {
		this.$nextTick(function () {
			this.GetEducation(this.$route.query.id)
		})
	},
	data () {
		return {
			alwaysArr: []
		}
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获取数据
		GetEducation (id) {
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: this.$$.mibb + 'GetEducationContent',
				data: { id: id, lang: localStorage.getItem('lang') || 2 },
				success: (data) => {
					if (data.status == 'SUCCESS') {
						this.alwaysArr = data.text[0]
					}
				},
				error: function (XMLHttpRequest) { }
			})
		}
	}
}
